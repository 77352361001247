export const styles = () => ({
  attributeNameWrap: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: 38,
    minWidth: 79,
  },
  inputWrap: {
    width: '100%',
    position: 'relative',
    display: 'inline-block',
  },
  attributeName: {
    backgroundColor: 'var(--color-button-secondary)',
    color: 'var(--color-text-on-secondary)',
    borderRadius: 50,
    padding: '1px 8px',
    fontSize: 14,
    height: 24,
    width: 'fit-content',
    margin: 0,
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  button: {
    fontSize: 14,
    color: 'var(--color-button-secondary)',
    padding: '8px 0px',
    borderTop: '1px solid rgba(53, 64, 82, 0.1)',
    textAlign: 'center',
    cursor: 'pointer',
  },
  addAttribute: {
    padding: '8px 12px',
    backgroundColor: 'var(--color-hover-overlay-primary)',
    cursor: 'pointer',
  },
  optionTitle: {
    color: 'rgba(53, 64, 82, 0.7)',
    fontSize: 14,
    margin: 0,
    textAlign: 'start',
  },
  option: {
    margin: '4px 0px 0px',
    color: 'var(--color-text-on-secondary)',
    padding: '1px 8px',
    borderRadius: 50,
    width: 'fit-content',
    fontSize: 14,
    cursor: 'pointer',
    backgroundColor: 'var(--color-button-secondary)',
  },
  customOption: {
    color: 'var(--color-white)',
    borderRadius: 50,
    padding: '1px 8px',
    height: 24,
    width: 'fit-content',
    margin: '5px 12px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  tooltip: {
    boxSizing: 'border-box',
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    borderRadius: '10px',
    zIndex: 9999,
    opacity: 1,
    textAlign: 'start',
    fontWeight: 'normal',
    padding: 10,
    backgroundColor: 'var(--color-text-secondary)',
  },
})

export const customStyles = (required, styles) => ({
  control: (provided, state) => {
    const error = required && !state.hasValue && !state.isFocused && state.selectProps.touched

    return {
      ...provided,
      ...styles,
      width: '100%',
      border: error ? '1px solid red' : '1px solid var(--color-mischka-grey)',
      outline: 'none',
      boxShadow: 'none',
      '&:hover': {
        border: error ? '1px solid red' : '1px solid rgba(53, 64, 82, 0.5)',
        boxShadow: 'none',
      },
    }
  },
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: 'var(--color-white)',
    display: 'none',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'var(--color-oxford-blue)',
    fontSize: 14,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: 'var(--color-white)',
    display: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: 'var(--color-white)',
    },
  }),
  groupHeading: base => ({
    ...base,
    color: 'var(--color-oxford-blue)',
    fontSize: 14,
    textTransform: 'none',
  }),
  option: (provided, state) => {
    return {
      ...provided,
      fontSize: 14,
      cursor: 'pointer',
      padding: '2px 0px',
      backgroundColor: state.isFocused ? 'var(--color-hover-overlay-primary)' : 'var(--color-white)',
      width: '100%',
      overflowX: 'hidden',
      '&:hover': {
        fontSize: 14,
        backgroundColor: state.isFocused ? 'var(--color-hover-overlay-primary)' : 'var(--color-white)',
      },
    }
  },
  menu: (provided, state) => ({
    ...provided,
    width: '100%',
    minWidth: 200,
    maxHeight: 250,
    padding: 0,
    zIndex: '20',
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: 200,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: 'rgba(53, 64, 82, 0.5)',
    fontSize: 14,
    width: 'max-content',
  }),
  noOptionsMessage: provided => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  clearIndicator: provided => ({
    ...provided,
    display: 'none',
  }),
})
